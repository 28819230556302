<template>
  <Dialog title="Uredite" :visible="isUpdateDialogVisible" :show-close="false">
    <div class="form">
      <Form :model="formData" :rules="rules" ref="componentForm" @submit.native.prevent="validateForm('componentForm')">
        <FormItem prop="item.content.company.name" label="Tvrtka">
          <Input
            v-model="formData.item.content.company.name"/>
        </FormItem>
        <FormItem prop="item.content.company.address" label="Adresa">
          <Input
            v-model="formData.item.content.company.address"/>
        </FormItem>
        <FormItem prop="item.content.company.vatNumber" label="OIB">
          <Input
            v-model="formData.item.content.company.vatNumber"/>
        </FormItem>
        <FormItem prop="item.content.contact.name" label="Ime i prezime">
          <Input
            v-model="formData.item.content.contact.name"/>
        </FormItem>
        <FormItem prop="item.content.contact.phone" label="Telefon">
          <Input
            v-model="formData.item.content.contact.phone"/>
        </FormItem>
        <FormItem prop="item.content.contact.mobilePhone" label="Mobitel">
          <Input
            v-model="formData.item.content.contact.mobilePhone"/>
        </FormItem>
        <FormItem prop="item.content.contact.email" label="E-mail">
          <Input
            v-model="formData.item.content.contact.email"/>
        </FormItem>
        <FormItem>
          <Button
            class="btn-grey"
            icon="el-icon-close"
            @click="$emit('closeDialog')"/>
          <Button
            native-type="submit"
            class="btn-light"
            icon="el-icon-check"
            :loading="loading"/>
        </FormItem>
      </Form>
    </div>
  </Dialog>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Element UI
import {Form, FormItem, Input, InputNumber, Button, Dialog} from 'element-ui';

// Logic
export default {
  name: 'AdministrationClientsRootModalsUpdate',
  mixins: [Common],
  components: {
    Form,
    FormItem,
    Input,
    InputNumber,
    Button,
    Dialog
  },
  props: [
    'operatingItem',
    'isUpdateDialogVisible'
  ],
  data() {
    return {
      formData: {
        item: {
          id: '',
          content: {
            company: {
              name: '',
              address: '',
              vatNumber: ''
            },
            contact: {
              name: '',
              phone: '',
              mobilePhone: '',
              email: '',
            }
          },
        }
      },
      rules: {
        item: {
          content: {
            contact: {
              name: [
                {
                  required: true,
                  message: 'Molimo ispunite polje',
                  trigger: ['blur']
                }
              ]
            }
          }
        }
      }
    }
  },
  methods: {
    validateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitForm(formName);
        } else {
          return false;
        }
      });
    },
    submitForm(formName) {
      this.setLoadingComponent(true);
      this.Axios.post(`administration/client/${this.formData.item.id}/update`, {
        content: JSON.stringify(this.formData.item.content)
      }).then(response => {
        this.message = response.data.content.message;
        this.status = response.data.content.status;
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
        }
      }).finally(() => {
        this.setLoadingComponent(false);
        this.setNotification();
        this.setData(formName);
        this.$emit('getItems');
        this.$emit('closeDialog');
      });
    },
    setData(formName) {
      this.formData = {
        item: {
          id: '',
          content: {
            company: {
              name: '',
              address: '',
              vatNumber: ''
            },
            contact: {
              name: '',
              phone: '',
              mobilePhone: '',
              email: '',
            }
          }
        }
      };
      this.$refs[formName].resetFields();
    }
  },
  watch: {
    operatingItem() {
      this.formData = {
        item: {
          id: this.operatingItem.id,
          content: {
            company: {
              name: this.operatingItem.content.company.name,
              address: this.operatingItem.content.company.address,
              vatNumber: this.operatingItem.content.company.vatNumber
            },
            contact: {
              name: this.operatingItem.content.contact.name,
              phone: this.operatingItem.content.contact.phone,
              mobilePhone: this.operatingItem.content.contact.mobilePhone,
              email: this.operatingItem.content.contact.email
            }
          }
        }
      };
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';
</style>
